import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import subscriptionImage from './subscribe-to-updates.png';
import PlannedChangeList from './Plans';
import plansData from './plans-data.json5';
import APIChangelogs from './APIChangelogs';
import NewsBlog from './NewsBlog';
import ChangelogSubscription from '~/components/ChangelogSubscription/ChangelogSubscription.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "news-and-notifications---beta"
    }}>{`News and Notifications - Beta`}</h1>
    <ChangelogSubscription subscriptionImage={<img src={subscriptionImage} alt="subcribe to updates" />} mdxType="ChangelogSubscription" />
    <PlannedChangeList plansData={plansData} mdxType="PlannedChangeList" />
    <APIChangelogs mdxType="APIChangelogs" />
    <NewsBlog mdxType="NewsBlog" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      